

export const skills = [
  {
    tech: 'JAVASCRIPT'
  },
  {
    tech: 'PYTHON'
  },
  {
    tech: 'TYPESCRIPT'
  },
  {
    tech: 'REACT'
  },
  {
    tech: 'EXPRESS'
  },
  {
    tech: 'NODE'
  },
  {
    tech: 'POSTGRES'
  },
  {
    tech: 'MONGODB'
  },
  {
    tech: 'GIT'
  },
  {
    tech: 'HTML'
  },
  {
    tech: 'CSS'
  },
  {
    tech: 'RECHARTS'
  },
  {
    tech: 'MUI'
  },
]